import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from './environment.dev';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const apiReq = req.clone({
      url: `${environment.api}/${req.url}`,
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return next.handle(apiReq).pipe(
      catchError((err) => {
        if (err.status === 401 && window.location.pathname != '/login') {
          // this.snackBar.open('Session Expired Please Login Again', 'Ok', {
          //   duration: 3000,
          // });
          localStorage.clear();
          console.log('inside error');
          // window.location.reload();
        } else if (window.location.pathname == '/login') {
          if (err.status == 401) {
            // this.snackBar.open('email or password invalid', 'ok', {
            //   duration: 3000,
            // });
          }
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
