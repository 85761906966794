import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpResponse } from './http.response';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  request<T>(arg0: {
    method: string;
    url: string;
    params: HttpParams;
    body: { dates: any; serviceName: any; serviceId: any; totalRoom: any };
  }) {
    throw new Error('Method not implemented.');
  }
  constructor(private _http: HttpClient) { }

  get<T>(url: string, params?: HttpParams): Promise<HttpResponse<T>> {
    return firstValueFrom(this._http.get<HttpResponse<T>>(url, { params }));
  }
  getData<T>(url: string, params?: HttpParams): Promise<T> {
    return firstValueFrom(
      this._http.get<T>(url, {
        params: params,
      })
    );
  }
  post<T>(
    url: string,
    data?: any,
    param?: HttpParams
  ): Promise<HttpResponse<T>> {
    return firstValueFrom(
      this._http.post<HttpResponse<T>>(url, data, { params: param })
    );
  }
  postData<T>(
    url: string,
    param?: HttpParams
  ): Promise<HttpResponse<T>> {
    return firstValueFrom(
      this._http.post<HttpResponse<T>>(url, {}, { params: param }) // Sending an empty body but including params
    );
  }


  delete<T>(url: string, param?: HttpParams): Promise<HttpResponse<T>> {
    return firstValueFrom(
      this._http.delete<HttpResponse<T>>(url, { params: param })
    );
  }

  patch<T>(
    url: string,
    data: any,
    param?: HttpParams
  ): Promise<HttpResponse<T>> {
    return firstValueFrom(
      this._http.patch<HttpResponse<T>>(url, data, { params: param })
    );
  }

  getObs<T>(url: string, params?: HttpParams) {
    return this._http.get<T>(url, {
      params: params,
    });
  }
}
