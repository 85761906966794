import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private _router: Router) {

  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // debugger;
    const Token = route.queryParams['token'];
    const id = route.queryParams['id'];
    if (Token) {
      localStorage.setItem('accessToken', Token);
      localStorage.setItem('_id', id);

      window.addEventListener('beforeunload', () => {
        localStorage.clear();
      });

      return true;
    } else if (localStorage.getItem('accessToken')) {
      return true;

    } else {
      this._router.navigate(['/']);
      return false;
    }
  }
}


