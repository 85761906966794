import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'passclub-v2-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  @Input() id?: string;
  loading: boolean = false;

  constructor(public modal: NgbActiveModal) { }

  deleteItem(): void {
    this.modal.close({ delete: true });
  }
}
