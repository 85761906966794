export const ADMINURL = {
  AMENITIES_LIST: '/home/amenities/amenities-list',
  AMENITIES_CATEGORY_LIST: '/home/amenities/amenities-category',
  CATEGORY_LIST: 'home/category/category-list',
  SUB_CATEGORY_LIST: 'home/category/sub-category',
  COUPON_LIST: '/home/coupon',
  CUSTOMER_LIST: '/home/customer',
  ENTITLEMENT_LIST: '/home/entitlement',
  PASS_LIST: 'home/pass',
  USER_LIST: 'home/user',
  VENDOR_LIST: '/home/vendor/vendor-list',
  VENDOR_DETAIL: 'edit-vendor/:id',
  BUSINESS_LIST: '/home/vendor/business-list',
  BOOKINGS: '/home/booking',
  RANK_LIST: '/home/rank',
  FAQ_LIST: '/home/faq',
  BUSINESSES: '/home/business-list',
  PRODUCT_RANK_LIST: '/home/product-rank',
  FORM_LIST: '/home/formly',
  PRICE_LIST: '/home/price-management',
  ROOM_LIST: '/home/products/rooms',
  PRODUCT_LIST: '/home/products',
  CUSTOMER_PASS_LIST: '/home/customer/customer-pass-list'
};

export const PAGINATION = {
  params: {
    limit: 5,
    skip: 0,
    sortDirection: 1,
    sortby: '',
    search: '',
    isDeleted: false
  },
};

export const PAGINATION_FOR_GETFULLLIST = {
  params: {
    limit: -1,
    skip: 0,
    sortDirection: 1,
    sortby: '',
    search: '',
    isDeleted: false
  },
};

export const formType: { [key: string]: string } = {
  '1': 'slot',
  '2': 'check in checkout',
  '3': 'whole day',
  '4': 'indefinite',
};
