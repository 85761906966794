export const MSG = {
  CATEGORY_DISABLED:
    'Currently We are Onboarding Hotel and Accommodation Partners only, For other Categories we shall be Operational soon',
  ERROR_SUBCATEGORY: 'Please fill Sub category field',
  ERROR_NAME: 'Please fill business name field',
  ERROR_DISPLAYNAME: 'Please fill display name field',
  ERROR_BUILTYEAR: 'Please fill built year field',
  ERROR_DESCRIPTION: 'Please fill description field',
  ERROR_NOOFRESTAURANT: 'Please fill No. Of Restaurant field',
  ERROR_NOOFROOM: 'Please fill No. Of Room field',
  ERROR_NOOFFLOORS: 'Please fill No. Of Floor field',
  ERROR_CURRENCY: 'Please fill Currency field',
  ERROR_CHECKINTIME: 'Please fill Check in Time field',
  ERROR_CHECKOUTTIME: 'Please fill Check Out Time field',
  ERROR_IS24HRSCHECKIN: 'Please checked 24 hrs check in',
  ERROR_PHONEREQUIRED: 'Please fill Phone Number field',
  ERROR_PHONEMINLENGTH: 'Phone number must be 10 digits long',
  ERROR_PHONEPATTERN: 'Phone number must be 10 digits long',
  ERROR_MOBILEREQUIRED: 'Please fill Mobile Number field',
  ERROR_MOBILEMINLENGTH: 'Please fill Mobile Number field',
  ERROR_MOBILEPATTERN: 'Mobile number must be 10 digits long',
  ERROR_EMAILREQUIRED: 'Please fill Email field',
  ERROR_EMAILPATTERN: 'Please enter valid Email',
  ERROR_LOCATIONNAME: 'Please fill location field',
  ERROR_BANKNAME: 'please fill Bank Name field',
  ERROR_IFSC: 'please fill IFSC Code field',
  ERROR_ACCOUNTHOLDERNAME: 'please fill Account Holder Name field',
  ERROR_ACCOUNTNUMBER: 'please fill Account Number field',
  ERROR_REENTERACCOUNTNUMBER: 'please fill Re-enter Account Number field',
  ERROR_ACCOUNTNUMBERMATCH: 'Account No. and Re-enter account No. didnt match',
  ERROR_GSTREQUIRED: 'please fill GST Number field',
  ERROR_GSTLENGTH: 'GST Number must be 15 characters long',
  ERROR_GSTPATTERN: 'GST Number is not Valid',
  ERROR_PANREQUIRED: 'please fill Valid PAN Number',
  ERROR_PANLENGTH: 'PAN Number must be 10 characters long',
  ERROR_PANPATTERN: 'PAN number is not Valid',
  ERROR_ALLDETAILS: 'Please Fill All Details',
  ERROR_CONTACTDETAILS: 'Please fill all valid contact details',
  ERROR_ROOMTYPE: 'please fill Room Type field',
  ERROR_TOTALROOMS: 'please fill Total Rooms field',
  ERROR_ROOMVIEW: 'please fill Room View field',
  ERROR_BEDTYPE: 'please fill Bed Type field',
  ERROR_EXTRABEDTYPE: 'please fill Extra Bed Type field',
  ERROR_PRICE: 'please fill Price field',
  ERROR_DIMENTIONLENGTH: 'please fill Length field',
  ERROR_DIMENTIONBREADTH: 'please fill Breadth field',
  ERROR_ADULTBASE: 'please fill Adult Base field',
  ERROR_ADULTMAX: 'please fill Adult Max field',
  ERROR_CHILDBASE: 'please fill Child Base field',
  ERROR_CHILDMAX: 'please fill Child Max field',

  ERROR_FIRSTNAME: 'please fill First Name field',
  ERROR_LASTNAME: 'please fill last Name field',
  ERROR_CONTACT: 'please fill Contact Number field',
  ERROR_PASSWORDPATTERN:
    'Password must be combination of minimum 1 lowercase letter,1 special character,1 number and min length 8 characters & max length 20',
  ERROR_PASSWORDMATCH: 'Password & Confirm Password Are Not Same',
  ERROR_ADDRESS: 'please fill Address field',
  ERROR_PINCODE: 'please fill Pin Code field',
  ERROR_COUNTRY: 'please fill Country field',
  ERROR_STATE: 'please fill State field',
  ERROR_CITY: 'please fill City field',
  ERROR_CONTACTPATTERN: 'Please Enter Correct Contact Number',
  ERROR_CONTACT_NAME: 'Name is required in contact details',
  ERROR_CONTACT_DESIGNATION: 'Designation is required in contact details',
  ERROR_CONTACT_MOBILE: 'please add valid mobile number',
  SUCCESS_OTPSENT: 'OTP Sent Successfully',

  ENTITLEMENT_ERROR: 'Please correct the entitlements entries.',
  PASS_RESET_LINK_SENT:
    'The password reset link has been sent to your registered email address.',
  PASS_RESET_LINK_SENT_FAILED: `Sorry, we couldn't send the password reset link to your email. Please try again later.`,
  VENDOR_NOT_FOUND:
    'Vendor not found. Please verify the vendor information and try again.',
  PASSWORD_RESET: `Success! Your password has been reset. You're good to go – log in now with your updated password.`,
  PASSWORD_RESET_FAILED: `Oops! It looks like there was an issue resetting your password. Please double-check your information and try again`,


  DELETE: {
    success: ' Deleted Successfully',
    Failed: ' deletation Failed',
  },
  CREATE: {
    success: ' Created Successfully',
    Failed: ' Addition Failed',
  },
  UPDATE: {
    success: ' Updated Successfully',
    Failed: ' Updation Failed',
  },

  CALENDAR: {
    DATES_SUCCESS_PRICE: 'Success! Your calendar dates price have been updated successfully',
    DATES_SUCCESS_AVAILABLE_ROOMS: 'Success! Changes have been successfully made to the availability of rooms for the specified dates',
    DATES_FAILED: 'Oops! It seems there was an issue updating the dates'
  },

  CATEGORY_DELETE: {
    success: 'Category Deleted Successfully',
    Failed: 'Category deletation Failed',
  },

  AMENITY_CATEGORY_DELETE: {
    success: 'Amenity Category Deleted Successfully',
    Failed: 'Amenity Category deletation Failed',
  },

  AMENITY_DELETE: {
    success: 'Amenity  Deleted Successfully',
    Failed: 'Amenity  deletation Failed',
  },

  USER_DELETE: {
    success: 'User Deleted Successfully',
    Failed: 'User deletation Failed',
  },

  CUSTOMER_DELETE: {
    success: 'Customer Deleted Successfully',
    Failed: 'Customer deletation Failed',
  },

  VENDOR_DELETE: {
    success: 'Vendor Deleted Successfully',
    Failed: 'Vendor deletation Failed',
  },

  VENDOR_LOGIN: {
    success: 'Vendor Login Successfully',
    Failed: 'Vendor Login Failed',
  },

  RANK_DELETE: {
    success: 'Rank Deleted Successfully',
    Failed: 'Rank deletation Failed',
  },

  BUSINESS_DELETE: {
    success: 'Business Deleted Successfully',
    Failed: 'Business deletation Failed',
  },

  SERVICE_DELETE: {
    success: 'Service Deleted Successfully',
    Failed: 'Service deletation Failed',
  },

  RANK: {
    NOTFOUND: 'Business details not found',
  },

  AMENITY_UPDATE: {
    success: 'Amenity Updated Successfully',
    Failed: 'Amenity Updation Failed',
  },

  AMENITY_CATEGORY_UPDATE: {
    success: 'Amenity category Updated Successfully',
    Failed: 'Amenity category Updation Failed',
  },

  AMENITY_REGISTER: {
    success: 'Amenity Added Successfully',
    Failed: 'Amenity addition Failed',
  },

  AMENITY_CATEGORY_REGISTER: {
    success: 'Amenity category Added Successfully',
    Failed: 'Amenity category addition Failed',
  },

  AMENITY_CATEGORY: {
    SUCCESS: {},
    FAILED: {
      LIST_ERROR: 'Unable to Fetch Amenities Categories',
      LIST_NOT_FOUND: 'Amenity categories not found',
    },
  },

  SUB_CATEGORY: {
    SUCCESS: {
      UPDATE: 'Sub-Category updated successfully',
      ADD: 'Sub-Category Added successfully',
      ORDER_UPDATE: 'Existing sub-category order updated',
    },

    FAILED: {
      UPDATE: 'Sub-Category updation failed',
      ADD: 'Sub-Category Addition failed',
      ORDER_UPDATE: 'Existing sub-category order not updated',
      MAIN_CATEGORY: 'Business Category List not fetched',
    },
  },

  CATEGORY: {
    SUCCESS: {
      UPDATE: 'Category updated successfully',
      ADD: 'Category Added successfully',
      ORDER_UPDATE: 'Existing category order updated',
    },

    FAILED: {
      UPDATE: 'Category updation failed',
      ADD: 'Category Addition failed',
      ORDER_UPDATE: 'Existing category order not updated',
    },
  },

  USER: {
    SUCCESS: {
      UPDATE: 'User updated successfully',
      ADD: 'User Added successfully',
    },

    FAILED: {
      UPDATE: 'User updation failed',
      ADD: 'User Addition failed',
    },
  },

  FAQ: {
    SUCCESS: {
      UPDATE: 'FAQ updated successfully',
      ADD: 'FAQ Added successfully',
    },

    FAILED: {
      UPDATE: 'FAQ updation failed',
      ADD: 'FAQ Addition failed',
    },
  },

  OVERDRAFT_UPDATE: 'Overdraft Updated Successfully',

  ADMIN_BOOKING: {
    REGISTER: 'booking was successful!',
    UPDATE: 'booking update successfully',
  },

  VENDOR_DASHBOARD: {
    PRODUCT_LIST_NOT_FOUND: 'Product list not found',
    PRODUCT_LIST_ERROR: 'Unable to retrieve product list',
    ACCOUNT_MANAGER_NOT_FOUND: 'Account Manager not found',
    ACCOUNT_MANAGER_ERROR: 'Unable to retrieve Account Manager Info',
    PASSCOIN_HISTORY_NOT_FOUND: 'PassCoin history not found',
    PASSCOIN_ERROR: 'Unable to retrieve Passcoin history',
    OVERDRAFT_LIMIT_NOT_FOUND: 'Overdraft limit not found',
    OVERDRAFT_ERROR: 'Unable to retrieve Overdraft limit detail',
    PASSCOIN_ACCEPT:
      'We have just updated this product  accept Passcoin status  ',
    PASSCOIN_ACCEPT_ERROR:
      'We apologize, but there seems to be an issue with the update product and Passcoin acceptance.',

  },

  BUSINESS: {
    SUCCESS: {
      UPDATE: 'Business updated successfully',
      ADD: 'Business Added successfully',
      ADD_DRAFT: 'Save Draft successfully'
    },

    FAILED: {
      UPDATE: 'Business updation failed',
      ADD: 'Business Addition failed',
    },
  },

  SERVICE: {
    SUCCESS: {
      UPDATE: 'Service updated successfully',
      ADD: 'Service Added successfully',
    },

    FAILED: {
      UPDATE: 'Service updation failed',
      ADD: 'Service Addition failed',
    },
  },





  SELECT: {
    DATE: 'Please select date',
    BUSINESS_SERVICE: 'Please select business and service',
  },

  URL_COPIED: 'URL copied to clipboard!',

  VENDOR_PROFILE: {
    SUCCESS: {
      UPDATE: 'Profile Updated Successfully',
      THEME: 'Theme Updated Successfully',
    },

    FAILED: {
      UPDATE: 'Profile Updation Successfully',
    },
  },

  BUSINESS_RANK: {
    SUCCESS: {
      ADD: 'Business rank added successfully',
      UPDATE: 'Business rank updated successfully',
    },
    FAILED: {
      ADD: 'Business rank addition failed',
      UPDATE: 'Business rank updation failed',
    },
  },

  ACCOUNT_MANAGER: {
    SUCCESS: {
      ASSIGN: 'Account manager successfully assigned',
    },
    FAILED: {
      ASSIGN: 'Account manager not assigned',
    },
  },

  PASSCOIN: {
    SUCCESS: {
      UPDATE: 'Passcoin updated successfully',
    },
    FAILED: {
      UPDATE: 'Passcoin updation failed',
      BALANCE: 'Insufficient funds',
    },
  },

  CUSTOM_FORM: {
    SUCCESS: {
      DELETE: 'Form deleted successfully',
      CREATE: 'Form created successfully',
      MODIFY: 'Form modified successfully',
    },
    FAILED: {
      DELETE: 'Form deletation failed',
      CREATE: 'Form creation failed',
      MODIFY: 'Form modification failed',
      NOTFOUND: 'selected sub-category  customfields not found',
    },
  },

  PRODUCT: {
    SUCCESS: {
      DELETE: 'Product deleted successfully',
      CREATE: 'Product created successfully',
      MODIFY: 'Product modified successfully',
    },
    FAILED: {
      DELETE: 'Product deletation failed',
      CREATE: 'Product creation failed',
      MODIFY: 'Product modification failed',
    },
  },

  ACCOUNT_VERIFICATION: {
    SUCCESS: {
      UPDATE: 'Account verification status updated successfully',
    },
    FAILED: {
      UPDATE: 'Account verification status updation failed',
    },
  },

  CONFIGRATION: {
    SUCCESS: {
      DELETE: 'Availability has been removed successfully',
      CREATE: 'CONFIGURATION created successfully',
      MODIFY: 'CONFIGURATION modified successfully',
    },
    FAILED: {
      DELETE: 'CONFIGURATION deletation failed',
      CREATE: 'CONFIGURATION creation failed',
      MODIFY: 'CONFIGURATION modification failed',
    },
  },

  SENT_MAIL: {
    SENT_EMAIL_SUCCESS:
      'Successfully sent! Kindly check your inbox for the next steps in the process.',
    SUCCESSFUl_VERIFICATION: 'Email Verification successfully completed',
  },

  EMAIL_VERIFICATION:
    'Oops! It seems like your email is not verified yet. Kindly complete the verification process.',

  PRE_PURCHASE_SAME_NAME_ERROR: 'A prepurchase item with this name already exists. Please choose a unique name to proceed.',
  CUSTOM_FORM_DELETE_CONFLICT: 'We are sorry, but this form cannot be deleted because it is associated with an active business.',

  ADMIN_BOOKING_FORM: {
    ERROR_BUSINESS: 'Please fill business field',
    ERROR_PRODUCT: 'Please fill product field',
    ERROR_CATEGORY: 'Business category not set. Please re-select the business',
    ERROR_CUSTOMER_TYPE: 'Please select customer type',
    ERROR_CUSTOMER_ID: 'Please select customer',
    ERROR_CUSTOMER_NAME: 'Please fill customer full name',
    ERROR_CUSTOMER_CONTACT_REQUIRED: 'Please fill customer contact number',
    ERROR_CUSTOMER_CONTACT_VALID: 'Customer contact number is invalid',
    ERROR_CUSTOMER_EMAIL_REQUIRED: 'Please fill customer email address',
    ERROR_CUSTOMER_EMAIL_VALID: 'Customer email is invalid',
    ERROR_BOOKING_TYPE: 'Booking Type not set. Please re-select the business',
    ERROR_FROM_DATE: 'Please fill valid from date',
    ERROR_TO_DATE: 'Please fill valid to date',
    ERROR_SLOT: 'Please select slot time',
    ERROR_PAYMENT_MODE: 'Please Select payment mode',
    ERROR_BOOKING_QUANTITY_REQUIRED: 'Please fill booking quantity',
    ERROR_BOOKING_QUANTITY_MIN: ' booking quantity at least 1 is required to continue',
    ERROR_PAYMENT_STATUS: 'Please select payment status',
    ERROR_ENTITLEMENT: 'Please select entitlement'
  },
  ERROR_UNIQUE_EMAIL: 'Email already exists please sign in',
  ERROR_UNIQUE_CONTACT: 'This contact is already registered. Please use a different one.'
};

export const HTTP_MSG = {
  NotFound: 'Sorry, the page you are looking for could not be found',
  default: 'Something went wrong',
  forbidden: 'You dont have permission to access this resource',
  Bad_Request:
    'Uh-oh! Something went wrong with your request. Please check and try again.',
};

export const FORM_VALIDATION: {
  [key: string]: string | { [key: string]: string };
} = {
  firstName: 'First Name is Required',
  lastName: 'Last Name  is Required',
  email: 'Email  is Required',
  contactNumber: 'Contact Number is Invalid',
  // {
  //   required: 'Contact Number  is Required',
  //   minlength: 'Contact Number must be 10 digits long',
  //   pattern: 'Contact Number is not valid',
  // },
  address: 'Address  is Required',
  pinCode: 'Pin Code  is Required',
  country: 'Country  is Required',
  state: 'State  is Required',
  city: 'City  is Required',
};
