export const API = {
  AMENITIES_LIST: 'amenities-list',
  USER: 'user',
  CUSTOMER: 'customer',
  ENTITLEMENT: {
    ENTITLEMENT: 'entitlement',
    LIST: 'entitlement/get-all',
    SINGLE: 'entitlement/get-single',
    MAX_PRICE: 'admin/max-product-price',
  },
  BOOKING: {
    LIST_CUSTOMER_BOOKING: 'customer-booking/all',
    LIST_VENDOR_BOOKING: 'vendors/booking-history',
    CREATE_CUSTOMER_BOOKING: 'customer-booking/register',
    CREATE_CUSTOMER_PRODUCT_BOOKING: 'customer-booking/product',
    CUSTOMER_BOOKING_INFO: 'customer-booking',
    MY_BOOKINGS: 'customer/booking-history',
  },
  BUSINESS: {
    LIST_FETCH_ALL: 'business-detail/fetch-all',
    SINGLE: 'business-detail/single',
    UPDATE: 'business-detail/modify',
    LIST_BY_CATEGORYIDS: 'business-detail/fetch-businesses-by-categoryid',
    LIST_BY_SUBCATEGORYIDS: 'business-detail/fetch-businesses-by-subcategoryid',
    DRAFT: 'business-detail/all-drafts',
    NON_HOTEL_BUSINESS_LIST: 'business-detail/fetch-all-non-hotel'
  },
  SERVICE: {
    LIST: 'service/fetch-by-businessId',
    DELETE: 'vendor/service/remove',
    SINGLE: 'vendor/service/single',
  },
  ADMIN_VENDOR_REGISTRATION: 'auth/vendor/register',
  ADMIN_VENDOR_LOGIN: 'auth/vendor/login',
  VENDOR: {
    SINGLE: 'vendors/single',
    LIST: 'vendors/get-all',
    UPDATE: 'vendors/profile',
    REMOVE: 'vendors/remove',
  },
  AdMIN_VENDOR_SERVICE_ADD: 'vendor/service/register',
  ADMIN_VENDOR_SERVICE_LIST: 'vendor/service/all',
  ADMIN_VENDOR_SERVICE_DELETE: 'vendor/service/remove',
  ADMIN_VENDOR_SERVICE_GET_SELECT: 'vendor/service/single',
  ADMIN_VENDOR_SERVICE_UPDATE: 'vendor/service/modify',
  COUPON: 'coupon',
  ADMIN_VENDOR_BUSINESS_REGISTER: 'business-detail/register',
  ADMIN_VENDOR_BUSINESS_LIST: 'business-detail/all',
  ADMIN_VENDOR_REGISTER: 'auth/vendor/register',
  ADMIN_VENDOR_BUSINESS_REMOVE: 'business-detail/remove',
  VENDOR_REGISTRATION: 'auth/vendor/register',
  VENDOR_LOGIN: 'auth/vendor/login',
  VENDOR_LOGIN_OTP_SENT: 'auth/vendor/login-2fa',
  VENDOR_LOGIN_OTP_VERIFY: 'auth/vendor/login-2fa/verify',
  VENDOR_GET_LIST: 'vendors/get',
  VENDOR_UPDATE: 'vendors/profile',
  VENDOR_SERVICE_ADD: 'vendor/service/register',
  VENDOR_SERVICE_LIST: 'vendor/service/all',
  VENDOR_SERVICE_UPDATE: 'vendor/service/modify',
  VENDOR_BUSINESS_REGISTER: 'business-detail/register',
  VENDOR_BUSINESS_LIST: 'business-detail/all',
  VENDOR_REGISTER: 'auth/vendor/register',
  VENDOR_BUSINESS_REMOVE: 'business-detail/remove',
  VENDOR_CHANGE_PASSWORD: 'vendors/change-password',
  CALENDER_PRICE: {
    REGISTER: 'price-list/register',
    EDIT: 'price-list/modify',
    LIST: 'price-list/fetch-all',
  },
  VENDOR_RESET_PASSWORD: 'auth/reset-password',
  VENDOR_AUTH: 'auth',
  ADMIN_LOGIN: 'auth/admin/login',
  CATEGORY: {
    LIST_ALL_CATEGORY_FOR_CUSTOMER: 'category',
    LIST: 'category/all',
    CREATE: 'category/register',
    DELETE: 'category/remove',
    SINGLE: 'category/single',
    MODIFY: 'category/modify',
  },
  ADMIN_CREATE_CUSTOM_FORM: 'customform/create',
  ADMIN_FETCH_CUSTOM_FORM: 'customform/fetch',
  ADMIN_DASHBOARD: 'admin/dashboard',
  ADMIN_CUSTOMER_LIST: 'admin/customer/fetch',
  ADMIN_CUSTOMER_MODIFY: 'admin/customer/profile',
  ADMIN_CUSTOMER_ADD: 'auth/user/register',
  ADMIN_CUSTOMER_DELETE: 'customer/remove',
  ADMIN_CUSTOMER_GET_SINGLE: 'customer/single-customer-profile',
  PASS: {
    CREATE: 'pass',
    LIST: 'pass/all',
    DELETE: 'pass/remove',
    SINGLE: 'pass/single',
  },
  USERS: {
    LIST: 'users/get',
    SINGLE: 'users/fetch/single',
    MODIFY: 'users/modify',
    ADD: 'admin/user/register',
    DELETE: 'users/remove',
  },
  AMENITIES_CATEGORY: {
    REGISTER: 'amenities-category/register',
    LIST: 'amenities-category/all',
    DELETE: 'amenities-category/remove',
    GET_SINGLE: 'amenities-category/single',
    EDIT: 'amenities-category/modify',
    GET_USING_CATEGORY: 'amenities-category/get-by-categoryid',
  },
  AMENITIES: {
    DELETE: 'amenities/remove',
    EDIT: 'amenities/modify',
    GET_SINGLE: 'amenities/single',
    LIST: 'amenities/all',
    REGISTER: 'amenities/register',
  },
  UPLOAD_IMAGE: 'file/upload',
  INVITE: {
    VENDOR: 'admin/invite/vendor',
    CUSTOMER: 'admin/invite/customer',
  },
  AVAILABLEROOM: {
    REGISTER: 'block-unblock-room/register',
    LIST: 'block-unblock-room/all',
    EDIT: 'block-unblock-room/modify',
    INFO: 'business-detail/calender',
  },
  CUSTOMER_REGISTER: 'auth/customer/register',
  CUSTOMER_LOGIN: 'auth/customer/login',
  hotels: 'business-detail/fetch-all/customer',
  PRODUCTS: 'business-detail/fetch-all/products',
  HOTEL_DETAILS: 'service/fetch-by-businessId-for-customer',
  CUSTOMER_PROFILE: 'customer/profile',
  SUB_CATEGORY: {
    REGISTER: 'sub-category/register',
    LIST: 'sub-category/all',
    DELETE: 'sub-category/remove',
    UPDATE: 'sub-category/modify',
    SINGLE: 'sub-category/single',
  },
  COUNTRY: 'countries',
  STATE: 'states',
  CITY: 'cities',
  GENRATE_TOKEN: 'admin/vendor/generate-token',
  PASSCOIN: {
    REGISTER: 'pass-coin-history/register',
    HISTORY: 'pass-coin-history/passCoinId',
  },
  ACCOUNT_MANAGER: {
    REGISTER: 'assign-account-manager/register',
    INFO: 'assign-account-manager',
  },
  GET_PRODUCT_LIST: 'product/fetch-by-vendorid',
  GET_SERVICE_LIST: 'service/fetch-by-vendorId',
  EXPORT_CALENDER_URL: 'calendar/download',
  BUSINESS_RANK: {
    REGISTER: 'ranking/register',
    LIST: 'ranking/get-all',
    FETCH_SINGLE: 'ranking/get-single',
    UPDATE: 'ranking/modify',
    DELETE: 'ranking/remove',
  },
  PRODUCT_RANK: {
    LIST: 'product-ranking/get-all',
    RAGISTER: 'product-ranking',
    FETCH_SINGLE: 'product-ranking/get-single',
    UPDATE: 'product-ranking',
    DELETE: 'product-ranking',
  },
  CUSTOM_FORM: {
    LIST: 'customform',
    CREATE: 'customform',
    FETCH_SINGLE: 'customform/find-by-formid',
    MODIFY: 'customform',
    DELETE: 'customform',
    FETCH_BY_SUBCATEGORYID: 'customform/find-by-subcategoryid',
  },
  PRODUCT: {
    CREATE: 'product',
    LIST: 'product/get-all',
    FETCH_SINGLE: 'product/get-single',
    MODIFY: 'product',
    DELETE: 'product',
    DETAILS_BY_BUSINESSID: 'product/fetch-by-businessId',
    FETCH_SINGLE_FOR_CUSTOMER: 'product/for-customer/get-single',
  },
  AVAILABILITY: {
    REGISTER: 'availability',
    LIST: 'availability/get-all',
    FETCH_SINGLE: 'availability/get-single',
    MODIFY: 'availability',
    DELETE: 'availability/remove',
    FETCH_CALENDER: 'availability/fetch-calendar',
    BULK_REGISTER: 'availability/bulk',
  },
  ADMIN_BOOKING: {
    REGISTER: 'booking/admin',
    LIST: 'booking/get-all',
    GET_SINGLE: 'booking/get-single',
    EXISTING_CUSTOMER: 'admin/customer-vendor-autosuggestion'
  },
  VENDOR_BOOKING: {
    LIST: 'booking/get-all-by-vendorid',
    SINGLE: 'booking/get-single'
  },
  AUTO_SUGGESTION: {
    BUSINESS: 'business-detail/autosuggestion',
    PRODUCT: 'product/autosuggestion',
  },
  EMAIL_VERIFICATION: {
    SEND_VENDOR_EMAIL: 'vendors/verification-mail',
    SEND_OTP: 'vendors/verify-mail-otp',
  },
  REVIEW: {
    POSTREVIEW: 'review/post',
    AVERAGEREVIEW: 'review/get-average-review-stars',
  },
  MULTI: {
    SUB_CATEGORY_LIST: 'sub-category/multi-category',
    SERVICE_LIST: 'admin/fetch-services-products-by-businessids',
  },
  FAQ: {
    faq: 'faq',
    GET_SINGLE: 'faq/get-single',
    GET_ALL: 'faq/get-all'
  },
  PREPURCHASE: {
    CREATE_OR_UPDATE_OR_DELETE: 'prepurchase',
    GET_ALL: 'prepurchase/get-all',
    GET_SINGLE: 'prepurchase/get-single',
    GET_ALL_BY_VENDORID: 'prepurchase/get-all-by-vendorid'
  },
  PASSCOIN_ACCEPT_ROOM: 'vendor/service/passcoinaccept',
  PRICE_MANAGEMENT: {

    CREATE_UPDATE_DELETE: 'price-management',
    LIST: 'price-management/get-all',
    GET_SINGLE: 'price-management/get-single'

  },
  ALL_VENDOR_HOTEL_ROOMS: 'fetch-all-rooms',
  ALL_VENDOR_PRODUCTS: 'product/get-all-products',
  ASSIGN_PRODUCT_RANK_TO_ROOMS: 'vendor/service/productrank',

  ENTITLEMENT_PURCHASE: {
    REGISTER_OR_UPDATE: 'entitlement-purchase',
    CUSTOMER_ENTITLEMENT_LIST: 'entitlement-purchase/get-all-by-customer',
    GET_SINGLE: 'entitlement-purchase/get-single'
  },

  PASS_PURCHASE: {
    REGISTER_OR_UPDATE: 'pass-purchase',
    CUSTOMER_PASS_LIST: 'pass-purchase/get-all-by-customer',
    GET_SINGLE: 'pass-purchase/get-single'
  },

  THIRD_PARTY_PRICE_AVAILABILITY: 'price-and-availability',
  NON_THIRD_PARTY_BUSINESS: 'service/fetch-non-third-party-by-businessId',
  CUSTOMER_PASS_ENTITLEMENTS: 'pass-purchase/get-all-redeemable-entitlements',
  UPDATE_OVERDRAFT_LIMIT: 'pass-coin/overdraftLimit',
  REGISTER_AS_CUSTOMER: 'auth/vendor-register-as-customer',
  ALL_CUSTOMER_VENDOR_PASSCOINS: 'pass-coin/all-passcoin',

  SUPER_ADMIN_WALLET: {
    GET: 'super-admin/passcoin',
    UPDATE: 'super-admin/overdraftlimit'
  },

  CUSTOMER_REGISTER_AS_VENDOR: 'auth/customer-register-as-vendor',
  check_IS_VENDOR_EXIST_BY_EMAIL: 'auth/unique-mail',
  CHECK_IS_VENDOR_EXIST_BY_CONTACT: 'auth/unique-contact',
  SUPER_ADMIN_PASSWORD_EMAIL_CHANGE: 'super-admin',
  ADMIN_CREDIT_LIMIT_DISTRIBUTED_HISTORY: 'pass-coin-history/overdraft-history',
  ADMIN_PASSCOIN_PAYABLE_HISTORY: 'pass-coin-history/payable-history',
  ADMIN_PASSCOIN_RECEIVABLE_HISTORY: 'pass-coin-history/receivable-history',
  BUSINESS_VISIBLE: 'business-detail/visibile',
  PASS_VISIBLE: 'pass/visible'
}


export const ID = {
  // HOTELID: '64f179b2e345325bede1fa44', // local db id
  HOTELID: '64edf2789ca783ff3e8256d9', //live db id
  PASSCOINID: '66752e83a4ce8f701493441e'  //Admin passcoinid
};
