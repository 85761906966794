import { Injectable, inject } from '@angular/core';
import { FileUpload } from './api-interfaces';
import { API } from './api.constant';
import { HttpResponse } from './http.response';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor() {}

  httpService = inject(HttpService);

  async readFile(file: File) {
    const reader = new FileReader();

    return new Promise<string | HttpResponse<File>>((resolve, reject) => {
      reader.addEventListener('load', async (event: any) => {
        try {
          let formData = new FormData();
          formData.append('file', file);
          formData.append('path', 'businessIMG');
          let result = await this.httpService.post<FileUpload>(
            API.UPLOAD_IMAGE,
            formData
          );
          if (result.status == 200) return resolve(result.response.fileUrls[0]);
          else reject(result);
        } catch (error) {
          reject(error);
        }
      });
      reader.readAsDataURL(file);
    });
  }
}
