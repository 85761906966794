import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationModel } from '@passclub-v2/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private router: Router) {}

  navigatePaginate(url: string, params?: PaginationModel) {
    return this.router.navigate([url], {
      queryParams: params,
    });
  }
}
