export interface VendorModel {
  state?: string;
  id?: string;
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  confirmPass?: string;
  countryCode?: string;
  contactNumber: string;
  address: string;
  country: string;
  city: string;
  pinCode: string;
  themeColor?: string;
  logo?: string;
  overdraftLimit?: number;
  passCoin?: number;
  accountVerification?: string;
  status?: string;
  isEmailVerified?: boolean;
  vendorID?: string;
  passCoinId?: string;
  roles?: string[]
}
export interface StateModel {
  state_name: string;
}
export interface CityModel {
  city_name: string;
}
export interface CountryModel {
  country_name: string;
  country_short_name: string;
  country_phone_code: string;
}
export interface LoginModel {
  email: string;
  password: string;
}
export interface LoginWithoutOtpModel {
  contactNumber: string;
}
export interface LoginWithOtpModel {
  contactNumber: string;
  otp?: string;
}
export interface ForgotPasswordModel {
  email: string;
}

export interface ResetPasswordModel {
  newPass: string;
  confirmPass: string;
}

export interface LatLongModel {
  lat: number;
  lng: number;
}
export interface BusinessModel {
  id?: string;
  _id?: string;
  name: string;
  category?: CategoryModel;
  subCategory?: SubCategoryModel;
  description: string;
  displayName: string;
  categoryId: string;
  subCategoryId: string;
  builtYear: string;
  noOfRestaurant: number;
  noOfRoom: number;
  noOfFloors: number;
  currency: string;
  checkInTime: string;
  checkOutTime: string;
  is24HrsCheckIn: boolean;
  contactInfo: ContactInfo;
  amenities?: string[];
  gallery?: Gallery[];
  location?: Marker;
  amenity?: Amenities[];
  isAcceptPassCoin: boolean;
  ranking?: businessRanking;
  bankDetail: BankDetail;
  calenderSyncURL: string;
  vendor?: VendorModel[];
  basicDetail?: any;
  form?: any;
  formType?: number;
  vendorId: string;
  createdAt?: Date;
  updatedAt?: Date;
  isVisible?: boolean
}
export interface ContactInfo {
  contacts: Contact[];
}
export interface Contact {
  name: string;
  mobile: string;
  email: string;
  designation: string;
}
export interface BankDetail {
  bankName: string;
  gstNo: string;
  panNo: string;
  accountHolderName: string;
  accountNo: number;
  reenterAccountNo: number;
  ifscCode: string;
}
export interface AuthTokenModel {
  token: string;
  _id: string;
  role: string;
  superAdmin?: SuperAdminModel
}

export interface SuperAdminModel {
  _id: string;
  email: string;
  roles: string;
  username: string;
  passCoinId: string;
}

export interface Marker {
  locationName: string;
  lat: number;
  lng: number;
}
export interface CategoryModel {
  id?: string;
  _id?: string;
  name: string;
  description?: string;
  image: string;
  active: boolean;
  order: number;
}
export interface SubCategoryModel {
  id?: string;
  _id?: string;
  name: string;
  description: string;
  categoryDetails?: CategoryModel[];
  categoryId: string;
  order: number;
}
export interface UserModel {
  id?: string;
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPass: string;
  contactNumber: string;
}

export interface CustomerModel {
  id?: string;
  _id?: string;
  fullName: string;
  email: string;
  contactNumber: number;
  country: string;
  countryCode: number;
  address: string;
  city: string;
  state: string;
  pinCode: number;
  password: string;
  confirmPass: string;
  passCoinId?: string;
  passCoin?: number;
  overdraftLimit?: number;
}
export interface PassModel {
  _id?: string;
  name: string;
  description: string;
  price: number;
  passImg: string;
  entitlements: Entitlements[];
  createdAt?: Date;
  updatedAt?: Date;
  isVisible?: boolean;
}
export interface Entitlements {
  entitlement: [];
  quantity: number;
  validity: number;
  frequency: number;
}
export interface AmenitiesCategory {
  id?: string;
  _id?: string;
  name: string;
  businessCategoryId: string;
  description: string;
  businessCategory?: CategoryModel[];
}
export interface Amenities {
  _id?: string;
  id?: string;
  amenitiesCategoryId: string;
  amenities: Addamenity[];
  amenityDetail?: AmenitiesCategory[];
}
export interface Addamenity {
  _id: string;
  name: string;
  description: string;
}
export interface AddAmenitiesResponseInterface {
  _id: string;
  name: string;
  description: string;
  amenitiesCategoryId: AmenitiesCategory;
}
export interface AmenitiesCategory {
  _id?: string;
  name: string;
  description: string;
  businessCategory?: CategoryModel[];
  image?: string;
}
export interface Gallery {
  url: string;
  isFav: boolean;
  type: number;
}
export interface FileUpload {
  fileUrls: string[];
}
export interface Service {
  businessId?: string;
  _id?: string;
  name: string;
  description: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails: string;
  roomOccupancy: ServiceRoomOccupancy;
  amenities: string[];
  amenity?: Amenities[];
  gallery?: Gallery[];
  price: string;
  isPassCoinAccept?: boolean;
  createdAt?: string;
  updatedAt?: string;
  fetchAvailabilityFromThirdParty?: boolean;
  fetchPriceFromThirdParty?: boolean;
}
export interface RoomService {
  _id?: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails?: string;
  roomOccupancy: ServiceRoomOccupancy;
  amenities?: Amenities[];
  gallery?: Gallery[];
  mealPlan?: MealPlanEnum[];
  extraAdultPrice: string;
  extraChildPrice: string;
  extraChildWBPrice: string;
  isPassCoinAccept?: boolean;
}
export interface Dimensions {
  length: string;
  breadth: string;
  measurementTypes: string;
}
export interface PaginationModel {
  limit: number;
  skip: number;
  sortDirection?: number;
  search?: string;
  sortby?: string;
}
export interface changePassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type SortDirection = 'asc' | 'desc' | '';
export interface SortEvent {
  column: string;
  direction: SortDirection;
}
export interface Invite {
  email: string;
}
export interface businessRanking {
  _id: string;
  rank: string;
}
export interface productRank {
  _id?: string;
  productRank: string;
}
export interface ServicePriceModel {
  id?: string;
  dates: priceDates[];
  serviceId?: string;
}
export interface DateModel {
  _id: string;
  date: string;
  price: string;
  id: string;
}
export interface PriceDate {
  date: DateModel[];
  price: number;
  _id?: string;
  serviceName?: string;
  totalRooms: number;
  serviceId?: string;
  availableRooms: number;
}
export interface priceDates {
  _id?: string;
  date: string;
  price: number;
}
export interface BlockUnblockRoomModel {
  id?: string;
  dates: BlockDates[];
  serviceId: string;
  serviceName: string;
  totalRooms: number;
}
export interface BlockDates {
  _id?: string;
  date: string;
  availableRoom: number | string;
}
export interface customerRegistrationModel {
  fullName: string;
  email: string;
  contactNumber: string;
  password: string;
}
export interface CustomerBookingModel {
  _id: string;
  serviceDetail: HotelDetail;
  productDetail: ProductModel;
  businessDetails: HistoryBookingBusinessDetail[];
  rooms: number;
  checkIn: Date;
  checkOut: Date;
  occupancy: Occupancy;
  customerDetail: customerProfile;
  guestDetail: GuestInfo;
  bookingDate: string;
  customerId?: string;
}
export interface GuestInfo {
  name: string;
  email: string;
  notes?: string;
  contactNo: string;
}
export interface Occupancy {
  adult: number;
  children?: number;
}
export interface HotelsModel {
  _id: string;
  VCCCurrency: string;
  builtYear: number;
  checkInTime: number;
  checkOutTime: number;
  currency: string;
  description: string;
  displayName: string;
  gallery: Gallery[];
  is24HrsCheckIn: boolean;
  name: string;
  noOfFloors: number;
  noOfRestaurant: number;
  timezone: string;
  type: string;
  vendor: VendorModel[];
  location?: Marker;
  amenities: Amenities[];
}
export interface CustomerHotelsModel {
  _id: string;
  VCCCurrency: string;
  builtYear: number;
  checkInTime: number;
  checkOutTime: number;
  currency: string;
  description: string;
  displayName: string;
  gallery: Gallery[];
  is24HrsCheckIn: boolean;
  name: string;
  noOfFloors: number;
  noOfRestaurant: number;
  timezone: string;
  type: string;
  vendor: VendorModel[];
  location?: Marker;
  amenities: Addamenity[];
  avgStars: number;
  totalReview: number,
  hotelReviews?: hotelAvgReview[];
  averageStars: number;
  reviewCount: number;
  hotelprice?: number,
  avgHotelReviews?: number
}
export interface HotelDetail {
  _id?: string;
  name: string;
  description: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails: BusinessModel;
  roomOccupancy: ServiceRoomOccupancy;
  amenities: string[];
  amenity?: Amenities[];
  gallery?: Gallery[];
  price: string;
  roomPrice: string;
  businessVendorInfo?: VendorModel;
  isPassCoinAccept?: boolean;
}
export interface CustomerHotelDetail {
  _id?: string;
  name: string;
  description: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails: BusinessModel;
  roomOccupancy: ServiceRoomOccupancy;
  amenities: Addamenity[];
  amenity?: Amenities[];
  gallery?: Gallery[];
  price: string;
  roomPrice: string;
  businessVendorInfo?: VendorModel;
  isPassCoinAccept?: boolean;
}
export interface customerProfile {
  _id?: string;
  fullName: string;
  email: string;
  contactNumber: string;
}
export interface HotelDetailForCustomer {
  _id?: string;
  name: string;
  description: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails: BusinessModel;
  roomOccupancy: ServiceRoomOccupancy;
  amenities: Addamenity[];
  amenity?: Amenities[];
  gallery?: Gallery[];
  price: string;
  roomPrice: string;
  businessVendorInfo?: VendorModel;
  isPassCoinAccept?: boolean;
  businessReviews?: BookingReviewDetails[];
}
export interface Response<T> {
  docs: T[];
  total: number;
}
export interface BookedInfo {
  businessId: string;
  month: number;
  year: number;
}
export interface BookedInfoResponse {
  blockedDates: string[];
  availableDates: string[];
}
export interface PassCoinRegister {
  recipientId?: string;
  amount: number;
  description: string;
  type: string;
  PassCoinBalance: string;
  overdraft: string;
}
export interface PassCoinModel {
  id?: string;
  recipientId: string;
  amount: number;
}
export interface PassCoinHistory {
  _id: string;
  senderId: string;
  recipientId: string;
  amount: number;
  description: string;
  type: string;
  sentAt: Date;
  senderTotalCoin: number;
  recipientTotalCoin: number;
  fullName?: string;
  email?: string;
}
export interface overdraftLimit {
  overdraftLimit: number;
}
export interface AccountManagerInfo {
  _id?: string;
  vendorId: string;
  user: UserModel;
}
export interface FormProps {
  key: string;
  type: string;
  props: {
    label: string;
    placeholder: string;
    required: boolean;
    options: optionModel[];
  };
}
export interface optionModel {
  value: string;
  label: string;
}
export interface FetchCustomFormModel {
  fields: FormProps[];
}
export interface CreateCustomFormModel {
  _id?: string;
  formName: string;
  formType: number;
  isDeleted: boolean;
  fields: FormProps[];
  createdAt: Date;
  updatedAt: Date;
  category: CategoryModel;
  subCategory: SubCategoryModel;
  categoryId?: string;    // categoryId and subCategoryId when single form get
  subCategoryId?: string
}
export interface AssignAccountManager {
  _id?: string;
  vendorId: string;
  userId: string;
}
export interface ApplyBusinessRankModel {
  rankId: string;
}
export interface TimeConfigureModel {
  _id?: string;
  fromDate: string;
  toDate: string;
  weekDays?: WeekDays;
  slotDuration?: number;
  noOfSlots?: number;
  slots?: Slot[];
  isMulti?: boolean
}
export interface WeekDays {
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
}
export interface ProductModel {
  _id?: string;
  availabilityId?: string;
  businessId?: string;
  name: string;
  description: string;
  regularPrice: number;
  passclubPrice: number;
  bookingLimit: number;
  unlimitedBooking: boolean;
  cancellationPolicy: string;
  isPassCoinAccept?: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export interface AvailabilityCalendarData {
  _id: string;
  productId: string;
  isAvailable: boolean;
  isDeleted: boolean;
  isMulti: boolean;
  fromDate: Date;
  toDate: Date;
  weekDays: WeekDays;
  slots: Slot[];
}
export interface FetchCalenderModel {
  availabilityId?: string
  isAvailable: boolean;
  date: string;
  slots: Slot[];
}
export interface Slot {
  startTime: string;
  endTime: string;
  isAvailable: boolean;
}
export interface AdminBookingSlot {
  startTime: string;
  endTime: string;
  isAvailable?: boolean;
}
export interface BookingInfo {
  bookingId: string;
  bookingType: BookingTypeEnum;
  bookingStatus: BookingStatusEnum;
  fromDate: Date;
  toDate: Date;
  isMulti: boolean;
  slot?: Slot;
  mealPlan?: string;
  totalAdults?: number;
  totalChildren?: number;
  totalGuests?: number;
  totalRooms?: number;
}
export interface BusinessInfo {
  businessId: string;
  businessName: string;
  categoryId: string;
  category: string;
  productId: string;
  product: string;
}
export interface VendorInfo {
  vendorId: string;
  firstName: string;
  lastName: string;
  contact: string;
  email: string;
}
export interface CustomerInfo {
  fullName: string;
  email: string;
  contact: string;
}
export interface PaymentInfo {
  paymentStatus: PaymentStatusEnum;
  paymentMode: PaymentModeEnum;
  price: number;
}
export interface singleBookingResponseBody {
  bookingInfo: BookingInfo;
  businessInfo: BusinessInfo;
  customerInfo: CustomerInfo;
  paymentInfo: PaymentInfo;
  vendorInfo: VendorInfo
}
export interface availabilityCalendarResponseBody {
  availabilityId: string;
  date: Date;
  isAvailable: boolean;
  slots: Slot[];
}

export enum VendorStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export enum VendorVerificationEnum {
  Pending = 'pending',
  Complete = 'complete',
}

export enum BookingTypeEnum {
  Slot = 'slot',
  Check = 'check in checkout',
  WholeDay = 'whole day',
  Indefinite = 'indefinite',
}

export enum MealPlanEnum {
  roomOnly = 'room only',
  roomWithBreakfast = 'room with breakfast',
  roomWithBreakfastLD = 'room with breakfast and lunch or dinner',
  roomAllMeals = 'room with all meals',
}

export enum FormTypeEnum {
  Slot = 1,
  Check = 2,
  WholeDay = 3,
  Indefinite = 4,
}

export enum PaymentStatusEnum {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Refunded = 'refunded',
}

export enum PaymentModeEnum {
  Pending = 'pending',
  Cash = 'cash',
  PassCoin = 'passcoin',
  PaymentGateway = 'paymentgateway',
  Entitlement = 'entitlement',
}

export enum BookingStatusEnum {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Refunded = 'refunded',
}

export enum WeekDaysEnum {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export interface viewConfigurationModel {
  availabilityId: string | null;
  isAvailable: boolean;
  date: Date;
  slots: Slot[];
}

export interface AdminBookingModel {
  bookingInfo: BookingInfoModel;
  businessInfo: BusinessInfoModel;
  customerInfo?: CustomerInfoModel;
  paymentInfo: PaymentInfoModel;
  vendorId: string;
  customerId?: string,
}

export interface vendorInfoModel {
  vendorId: string;
  firstName: string;
  lastName: string;
  email: string;
  contact: string;
}
export interface BookingInfoModel {
  bookingId: string;
  bookingType: string;
  fromDate: string;
  toDate: string;
  isMulti: boolean;
  slot?: {
    startTime: string;
    endTime: string;
  };
  mealPlan?: string;
  totalAdults?: number;
  totalChildren?: number;
  totalGuests?: number;
  totalRooms?: number
}

export interface BusinessInfoModel {
  businessId: string;
  businessName?: string;
  categoryId: string;
  category?: string;
  productId: string;
  product?: string;
}

export interface CustomerInfoModel {
  fullName: string;
  email: string;
  contact: string;
}

export interface PaymentInfoModel {
  entitlementId?: string;
  passId?: string;
  bookedQuantity: number;
  paymentStatus: string;
  price: number;
  paymentMode: string;
}

export interface BookingHistoryModel {
  _id: string;
  bookingId: string;
  bookingStatus: string;
  bookingType: string;
  categoryId: string;
  contact?: string;
  email?: string;
  fromDate: Date;
  fullName?: string;
  isDeleted: boolean;
  isMulti: boolean;
  paymentStatus: string;
  price: number;
  toDate: Date;
  createdAt: Date;
  updatedAt: Date;
  businessId: string;
  businessName: string;
  productId: string;
  productName: string;
  vendorFirstName: string;
  vendorLastName: string;
  vendorContact: string;
  vendorEmail: string;
}

export interface ProductModelForCustomer {
  _id?: string;
  availabilityId?: string;
  businessId?: string;
  name: string;
  description: string;
  regularPrice: number;
  passclubPrice: number;
  bookingLimit: number;
  unlimitedBooking: boolean;
  cancellationPolicy: string;
  businessDetails: BusinessModel[];
  businessReviews: BookingReviewDetails[];
  amenities?: Addamenity[];
}

export interface CreateReviewModel {
  customerId: string;
  reviewContent: string;
  businessDetails: string;
  serviceId: string;
  bookingId: string;
}
export interface BookingHistory {
  _id: string;
  rooms: number;
  checkIn: Date;
  checkOut: Date;
  bookingDate: Date;
  occupancy: Occupancy;
  guestDetail: GuestInfo;
  price: string;
  paymentStatus?: string;
  businessDetails: HistoryBookingBusinessDetail[];
  serviceDetail: CustomerHistoryService;
  productDetail: ProductModel;
  slot?: Slot;
  productBookedDate?: Date;
}

export interface CustomerHistoryService {
  _id: string;
  roomType: string;
  totalRooms: number;
  roomView: string;
  bedType: string;
  price: string;
  extraBedType: string;
  dimensions: Dimensions;
  roomOccupancy: ServiceRoomOccupancy;
  gallery: any[];
  businessDetails: HistoryBookingBusinessDetail[];
  vendorId: string;
  amenities: string[];
}

export interface HistoryBookingBusinessDetail {
  _id: string;
  amenities: string[];
  bankDetail: BankDetail;
  categoryId: string;
  contactInfo: ContactInfo[];
  description: string;
  rulesRegulations: string;
  termsOfUse: string;
  displayName: string;
  formId: string;
  gallery: Gallery[];
  isDeleted: boolean;
  location: Location;
  name: string;
  subCategoryId: string;
  timezone: string;
  vendorId: string;
  createdAt: Date;
  updatedAt: Date;
  basicDetail: BasicDetail;
}
export interface BasicDetail {
  builtYear?: string;
  noOfRestaurant?: string;
  noOfRoom?: string;
  noOfFloors?: string;
  currency?: string;
  checkInTime?: string;
  checkOutTime?: string;
  is24HrsCheckIn?: boolean;
  isAcceptPassCoin?: boolean;
  calenderSyncURL?: string;
}
export interface BusinessDetailsQueryParams {
  prices?: string;
  stars?: string;
  rating?: string;
  skip?: number;
  limit?: number;
  sortDirection?: number;
  categoryId?: string;
  categoryName?: string;
  date?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  search?: string;
  checkIn?: string;
  checkOut?: string;
  adult?: number;
  children?: number;
  rooms?: number;
  amenities?: string;
  sortby?: string;
}
export interface BookingReviewDetails {
  _id: string;
  reviewContent: string;
  userReviewDetails: UserReviewDetails;
  createdAt: string;
}

export interface UserReviewDetails {
  _id: string;
  fullName: string;
  email: string;
  password: string;
  contactNumber: string;
  passCoin: number;
  isDeleted: boolean;
  roles: string[];
  createdAt: Date;
  updatedAt: Date;
}
export interface UserReviewCount {
  _id: string;
  reviewAvg: number;
  totalReviewCount: number;
}
export interface UserReviews {
  businessReviews?: BookingReviewDetails[];
  AvgRevew?: number;
  ReviewCount?: allReviewAvgCount;
}
export interface allReviewAvgCount {
  _id?: string;
  CleanlinessAvg?: number;
  LocationAvgStars?: number;
  StaffAvgStars?: number;
  ValueformoneyAvgStars?: number;
  ComfortAvgStars?: number;
  FacilitiesAvgStars?: number;
  FreeWiFiAvgStars?: number;
  totalAvgStars?: number;
  totalReviewCount?: number;
}
export interface hotelAvgReview {
  _id: string;
  averageStars: number
}
export interface ProductAllBUsinessModel {
  _id: string;
  name: string;
  description: string;
  businessId: string;
  passclubPrice: number;
  regularPrice: number;
  businessName: string;
  businessDisplayName: string;
  categoryName: string;
  categoryId: string;
  isPassCoinAccept: boolean;
}

export interface RoomsAllBusinessModel {
  _id: string;
  roomType: string;
  bedType: string;
  businessDetails: BusinessModel;
  isPassCoinAccept: boolean;
  category: CategoryModel;
}

export interface VendorProfileUpdate {
  state?: string;
  _id?: string;
  firstName: string;
  lastName: string;
  countryCode?: string;
  address: string;
  country: string;
  city: string;
  pinCode: string;
  themeColor?: string;
  logo?: string;
}

export interface EntitlementModel {
  _id?: string;
  name: string;
  description: string;
  minPrice: number;
  maxPrice: number;
  categoryId: string;
  subCategoryIds: [];
  businessIds: [];
  businessRankIds: [];
  productIds: [];
  productRankIds: [];
  countries: string;
  states: string;
  cities: string;
  createdAt?: string;
  updatedAt?: string;
  isEntirePriceRange: boolean;
  mealPlan?: string[];
  maxAdults?: number;
  maxChildren?: number;
  maxGuests?: number;
  maxRooms?: number;

  categoryName?: [];

}

export interface ServiceRoomOccupancy {
  adultBase: number;
  adultMax: number;
  childBase: number;
  childMax: number;
}

export interface ServiceBody {
  vendorId: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails: string;
  roomOccupancy: ServiceRoomOccupancy;
  amenities: string[];
  gallery: Gallery[];
  extraAdultPrice: string;
  extraChildPrice: string;
  extraChildWBPrice: string;
  mealPlan: string;
  isPassCoinAccept?: boolean;
}
export interface FaqModel {
  _id?: string;
  question: string;
  answer: string;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface PrePurchaseModel {
  _id?: string;
  name: string;
  description: string;
  vendorId: string;
  businessId: string;
  productId: string;
  totalQuantity: number;
  expiry: string;
}
export interface FetchPrePurchaseModel {
  _id: string;
  name: string;
  description: string;
  totalQuantity: number;
  businessId: BusinessID;
  vendorId: VendorID;
  createdAt: Date;
  updatedAt: Date;
  productId: ProductID;
  expiry: Date;
  consumedQuantity?: number;
  remainingQuantity?: number;
}
export interface BusinessID {
  _id: string;
  name: string;
}
export interface ProductID {
  _id: string;
  name: string;
  type: string;
}
export interface VendorID {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}
export interface BookingStatusUpdateModel {
  paymentStatus: string;
  bookingStatus: string;
}
export interface ServiceProductModel {
  _id: string;
  name: string;
  businessId: string;
  id: string;
  type: string;
}
export interface EditDateDialogModel {
  priceObj: priceDates[];
  blockObj: BlockDates[];
  serviceInfo: Service;
  serviceIds: [];
  disableAvailability: boolean;
  disablePrice: boolean;
}
export interface PassCoinDashboardData {
  _id: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  passCoin: number;
  overdraftLimit: number;
}

export interface VendorPriceObj {
  commissionOnCash: number;
  commissionOnPassCoin: number;
  gstCommissionOnCash: number;
  gstCommissionOnPassCoin: number;
}
export interface CustomerPriceObj {
  markupOnCash: number;
  markupOnPassCoin: number;
  gstMarkupOnCash: number;
  gstMarkupOnPassCoin: number;
}
export interface RoomPriceManagement {
  _id?: string;
  vendorPrice: VendorPriceObj;
  subCategoryId: string;
  categoryId: string;
  categoryName?: string;
  subcategoryName?: string;
}

export interface AllVendorProductsModel {
  _id: string;
  name: string;
  description: string;
  businessId: string;
  passclubPrice: number;
  regularPrice: number;
  unlimitedBooking: boolean;
  bookingLimit: number;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  cancellationPolicy: string;
  isPassCoinAccept: boolean;
  businessName: string;
  categoryName: string;
  vendorFirstName: string;
  vendorLastName: string;
  vendorfullName: string;
}
export interface AllVendorRoomsModel {
  _id: string;
  roomType: string;
  totalRooms: number;
  roomView: string;
  bedType: string;
  mealPlan: string;
  extraAdultPrice: string;
  extraChildPrice: string;
  extraChildWBPrice: string;
  extraBedType: string;
  dimensions: Dimensions;
  roomOccupancy: ServiceRoomOccupancy;
  gallery: Gallery[];
  businessDetails: BusinessModel;
  isPassCoinAccept: boolean;
  // pricelistsDetails: any[];
  category: CategoryModel;
  vendorInfo: VendorInfo;
  amenities: Addamenity[];
}
// isAvailable is not a required field for createAdminBookingWithSlots.slot
// DO NOT REMOVE
export interface createAdminBookingWithSlots {
  bookedQuantity?: number;
  bookingId: string;
  bookingStatus: BookingStatusEnum;
  bookingType: BookingTypeEnum;
  businessId: string;
  categoryId: string;
  contact?: string;
  customerId: string;
  email?: string;
  // entitlementId?: string;
  fromDate: Date;
  fullName?: string;
  isDeleted: boolean;
  isMulti: boolean;
  // passId?: string;
  paymentStatus: PaymentStatusEnum;
  price: number;
  productId: string;
  slot: AdminBookingSlot;
  toDate: Date;
  vendorId: string;
}

export interface createAdminBooking {
  bookingId: string;
  bookingStatus: BookingStatusEnum;
  bookingType: BookingTypeEnum;
  businessId: string;
  categoryId: string;
  contact?: string;
  customerId: string;
  email?: string;
  fromDate: Date;
  fullName?: string;
  isDeleted: boolean;
  isMulti: boolean;
  paymentStatus: PaymentStatusEnum;
  price: number;
  productId: string;
  slot: Slot;
  toDate: Date;
  vendorId: string;
}

export interface ServiceRoomOccupancy {
  adultBase: number;
  adultMax: number;
  childBase: number;
  childMax: number;
}

export interface ServiceBody {
  vendorId: string;
  roomType: string;
  totalRooms: string;
  roomView: string;
  bedType: string;
  extraBedType: string;
  dimensions: Dimensions;
  businessDetails: string;
  roomOccupancy: ServiceRoomOccupancy;
  amenities: string[];
  gallery: Gallery[];
  extraAdultPrice: string;
  extraChildPrice: string;
  extraChildWBPrice: string;
  mealPlan: string;
  isPassCoinAccept?: boolean;
}
export interface FaqModel {
  _id?: string;
  question: string;
  answer: string;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

// export interface PrePurchaseModel {
//   name: string;
//   description: string;
//   expiry: string;
//   type: string;
//   serviceIds: [];
// }
export interface PassCoinDashboardData {
  _id: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  passCoin: number;
  overdraftLimit: number;
}


export interface CustomerVendorSyncModel {
  state?: string;
  pinCode?: string;
  city?: string;
  area?: string;
  landmark?: string;
  country?: string;
  address?: string;
  contactNumber?: string;
  countryCode?: string;
  password?: string;
  email?: string;
  lastName?: string;
  firstName?: string;
  fullName?: string;
  passCoin?: string;
}
export interface VendorRegisterAsCustomer {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  contactNumber: string,
}

export interface VenderRegisterAsCustomer {
  user: {
    otp: string;
    _id: string;
    vendorID: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    contactNumber: string;
    address: string;
    state: string;
    city: string;
    pinCode: string;
    country: string;
    themeColor: string;
    logo: string;
    passCoinId?: string;
    isEmailVerified: boolean;
    isDeleted: boolean;
    status: string;
    accountVerification: string;
    roles: string[];
    createdAt: Date;
    updatedAt: Date;
  }
}

export interface CustomerRegisterAsVendor {
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    password: string;
    contactNumber: string;
    address: string;
    area: string;
    landmark: string;
    city: string;
    state: string;
    country: string;
    passCoinId?: string;
    isDeleted: boolean;
    roles: string[];
    createdAt: Date;
    updatedAt: Date;
    pinCode: string;
  }
}

export interface EditDateDialogModel {
  priceObj: priceDates[];
  blockObj: BlockDates[];
  serviceInfo: Service;
  serviceIds: [];
}
export interface PassCoinDashboardData {
  _id: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  passCoin: number;
  overdraftLimit: number;
}

export interface createAdminBooking {
  bookingId: string;
  bookingStatus: BookingStatusEnum;
  bookingType: BookingTypeEnum;
  businessId: string;
  categoryId: string;
  contact?: string;
  customerId: string;
  email?: string;
  fromDate: Date;
  fullName?: string;
  isDeleted: boolean;
  isMulti: boolean;
  paymentStatus: PaymentStatusEnum;
  price: number;
  productId: string;
  slot: Slot;
  toDate: Date;
  vendorId: string;
}
export interface SuperAdminPassCoins {
  _id: string;
  passCoin: number;
  overdraftLimit: number;
  spendLimit: number;
  createdAt: Date;
  updatedAt: Date;
}
export interface SuperAdminPassCoinTotal {
  totalOverDraftLimitUsed: number;
  totalPassCoinUsed: number;
  passCoinPayable: number;
  passCoinReceivable: number;
}

export interface SuperAdminPassCoin {
  passCoins: SuperAdminPassCoins[];
  total: SuperAdminPassCoinTotal[];
}

// export interface AssignEntitlementModel {
//   customerId: string;
//   entitlementId: string;
//   quantity: number;
//   frequency: number;
//   expiry: string;
// }

export interface AssignPassModel {
  customerId: string;
  passId: string;
  paymentStatus: string;
  paymentId?: string
}

export interface CustomerPassesModel {
  _id: string;
  pass: FetchCustomerPassModel;
  paymentStatus: string;
  customerDetails: {
    _id: string;
    fullName: string;
  };
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}
export interface FetchCustomerPassModel {
  name: string;
  description: string;
  price: number;
  passImg: string;
  entitlements: PassEntitlement[];
  _id: string;

}

export interface PassEntitlement {
  entitlement: [{
    _id: string;
    name: string;
  }];
  quantity: number;
  frequency: number;
  expiry: Date;
  frequencyExpiry: number;
  consumed: number;
  remaining: number;
}

export interface Customer_Vendor_PasscoinModel {
  passCoin: number;
  overdraftLimit: number;
  passCoinId: string;
  fullName: string;
  role: string;
  vendorId: string;
  customerId: string;
  email: string;
  api?: string;
  isDeleted: boolean;
}

export interface AdminWalletDetailModel {
  _id: string;
  passCoin: number;
  overdraftLimit: number;
  createdAt: Date;
  updatedAt: Date;
  totalOverDraftLimitUsed: number;
  totalPassCoinUsed: number;
  spendLimit: number;
  passCoinPayable: number;
  passCoinReceivable: number;
  commissionPassCoin: number;
  commissionCash: number;
  totalCommission: number;
}

export interface updateSuperAdminBalance {
  _id: string,
  passCoin: number,
  overdraftLimit: number,
}

export interface customerEntitlementsModel {
  _id: string;
  passId: string;
  frequency: number;
  quantity: number;
  validity: number;
  name: string;
  entitlementId: string;
  expiry: Date;
}
export interface FieldGroup {
  key: string;
  fieldType: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  options?: optionModel[];
}

export interface PriceManagementModel {
  _id?: string
  categoryId: string;
  subCategoryId: string;
  subcategoryName?: string;
  categoryName?: string;
  vendorPrice: {
    commissionOnCash: number;
    commissionOnPassCoin: number;
    gstCommissionOnCash: number;
    gstCommissionOnPassCoin: number;
  };
  customerPrice?: {
    markupOnCash: number;
    markupOnPassCoin: number;
    markupPercentageOnCash: number;
    markupPercentageOnPassCoin: number;
    gstMarkupOnCash: number;
    gstMarkupOnPassCoin: number;
  };


}

export interface CustomerPrice {
  markupOnCash: string;
  markupOnPassCoin: string;
  markupPercentageOnCash: string;
  markupPercentageOnPassCoin: string;
  gstMarkupOnCash: string;
  gstMarkupOnPassCoin: string;
}

export interface VendorPrice {
  commissionOnCash: string;
  commissionOnPassCoin: string;
  gstCommissionOnCash: string;
  gstCommissionOnPassCoin: string;
}

export interface TraviyoGst {
  CGSTAmount: 0.0000;
  CGSTRate: 0;
  CessAmount: 0.0000;
  CessRate: 0;
  IGSTAmount: 23.0144;
  IGSTRate: 18;
  SGSTAmount: 0.0000;
  SGSTRate: 0;
  TaxableAmount: 127.86
}

export interface TraviyoPrice {
  CurrencyCode: string;
  RoomPrice: number
  Tax: number
  ExtraGuestCharge: number
  ChildCharge: number
  OtherCharges: number
  Discount: number
  PublishedPrice: number
  PublishedPriceRoundedOff: number
  OfferedPrice: number
  OfferedPriceRoundedOff: number
  AgentCommission: number
  AgentMarkUp: number
  ServiceTax: number
  TCS: number
  TDS: number
  ServiceCharge: number
  TotalGSTAmount: number
  GST: TraviyoGst;
}

export interface TraviyoHotelListResponse {
  businesses: TraviyoHotelList[];
  total: number;
}
export interface TraviyoHotelList {
  IsHotDeal?: false;
  ResultIndex?: 102;
  HotelCode?: string;
  HotelName: string;
  HotelCategory: string;
  StarRating?: number;
  HotelDescription: string;
  HotelPromotion?: string;
  HotelPolicy: string;
  Price: TraviyoPrice;
  HotelPicture: string;
  HotelAddress: string;
  HotelContactNo: string;
  HotelMap?: null;
  Latitude: string;
  Longitude: string;
  HotelLocation: string;
  SupplierPrice?: null;
  amenities: any[];
  rooms: any[];
  // RoomDetails: TraviyoRoomDetails[];
}

export interface TraviyoHotelDetails {
  HotelInfoResult: {
    HotelDetails: {
      HotelCode?: "",
      HotelName: string,
      StarRating?: null,
      HotelURL: string,
      Description: string,
      Attractions?: [],
      HotelFacilities: string[],
      HotelPolicy: null,
      SpecialInstructions?: null,
      HotelPictures: string[],
      HotelPicture: string,
      Images: string[],
      Address: string,
      CountryName: string,
      PinCode: string,
      HotelContactNo: string,
      FaxNumber?: string,
      Email: string,
      Latitude: string,
      Longitude: string,
      RoomData?: TraviyoRoomDetails[],
      RoomFacilities?: null,
      Services?: null
    }
  }
}

export interface TraviyoRoomDayRate {
  Amount: number,
  Date: Date,
}

export interface TraviyoSingleHotelPriceList {
  RoomId: string,
  DayRates: TraviyoRoomDayRate[]
}
export interface TraviyoRoomDetails {
  AvailabilityType?: string;
  ChildCount: number;
  RequireAllPaxDetails?: true;
  RoomId: string;
  RoomStatus?: number;
  RoomIndex?: number;
  RoomTypeCode?: string;
  RoomDescription: string;
  RoomTypeName: string;
  RatePlanCode?: string;
  RatePlan?: number;
  RatePlanName: string;
  InfoSource?: string;
  SequenceNo?: string;
  DayRates: TraviyoRoomDayRate[],
  IsPerStay?: false,
  SupplierPrice?: null,
  Price: TraviyoPrice
  RoomPromotion?: string,
  Amenities?: string[],
  Amenity?: string[],
  SmokingPreference?: string,
  BedTypes: [],
  HotelSupplements?: [],
  LastCancellationDate?: Date,
  CancellationPolicies?: [
    {
      Charge: number,
      ChargeType: number,
      Currency: string,
      FromDate: Date,
      ToDate: Date
    }
  ],
  LastVoucherDate?: Date,
  CancellationPolicy?: string,
  Inclusion?: string[],
  IsPassportMandatory?: boolean,
  IsPANMandatory?: boolean
}