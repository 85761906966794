<div class="modal-header">
  <h4 class="modal-title">Delete Confirmation</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('cancel click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">Are you sure you want to delete this item?</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="modal.dismiss('cancel click')"
  >
    Cancel
  </button>
  <button type="button" class="btn btn-danger" (click)="deleteItem()">
    <span *ngIf="loading">Deletting...</span>
    <span *ngIf="!loading">Delete</span>
  </button>
</div>
